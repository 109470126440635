import { useMutation, useQueryClient } from "@tanstack/react-query";
import axios, { AxiosError } from "axios";
import { useContext } from "react";
import { AlertType, AlertsContext, IAlert } from "../../../components/molecules/Alerts/AlertsContext";
import { getAxiosRequestConfig } from "../../../functions/getAxiosRequestConfig";
import { Constants } from "../../../services/Constants/Constants";
import { telemetry } from "../../../services/Telemetry/Telemetry";
import { useAuth } from "../../useAuth";
import { QueryCacheKey } from "../QueryCacheKey";
import { TelemetryEvent } from "@headbot/library";

interface IResponse {}

const getMutationQuery = (token: string | null) => {
    const restartRequest = async (jobId: string): Promise<IResponse> => {
        const config = getAxiosRequestConfig(token);
        const request = axios.post(`${Constants.ApiRoot}/admin/job/${jobId}/restart`, {}, config);

        const { data } = await request;
        return data;
    };
    return restartRequest;
};

export const useRestartJob = () => {
    const { authToken } = useAuth();
    const { pushAlert } = useContext(AlertsContext);
    const queryClient = useQueryClient();

    const mutation = useMutation<IResponse, AxiosError, string>((jobId) => getMutationQuery(authToken)(jobId), {
        onMutate: () => {
            telemetry.Log(TelemetryEvent.RestartJobInit, null);
        },
        onSuccess: (_data) => {
            telemetry.Log(TelemetryEvent.RestartJobSuccess, null);
            queryClient.invalidateQueries([QueryCacheKey.Job], { exact: false });
        },
        onError: (error, _variables, _context) => {
            const e = error as AxiosError<any>;
            const restartError = JSON.stringify(e.response?.data?.error);
            const alert: IAlert = {
                type: AlertType.Error,
                message: restartError,
                timeout: Constants.AlertModalTimeoutMs,
            };
            pushAlert?.(alert);
            telemetry.Log(TelemetryEvent.RestartJobError, restartError);
        },
    });

    return mutation;
};
