import * as React from "react";
import { IJob, useJobList } from "../../../../hooks/api/admin/useJobList";
import { Button, Table } from "antd";
import { useRestartJob } from "../../../../hooks/api/admin/useRestartJob";
import { RouterLink } from "../../../molecules/RouterLink/RouterLink";
import { HeadbotRoute } from "../../../../services/Constants/HeadbotRoute";
import { JobStatus } from "@headbot/library";

interface IProps {}

interface IProps {
    readonly status: "completed" | "started";
}

export const JobsList: React.FC<IProps> = ({ status }) => {
    const jobsData = useJobList(status);
    const restartJob = useRestartJob();

    const columns = [
        {
            title: "Job ID",
            dataIndex: "_id",
            render: (_text: string, job: IJob) => {
                return <RouterLink href={HeadbotRoute.AdminJobDetails(job._id)}>{job._id}</RouterLink>;
            },
        },
        {
            title: "Created",
            dataIndex: "created",
            key: "created",
        },
        {
            title: "Coupon Code",
            dataIndex: "couponCode",
            key: "couponCode",
        },
        {
            title: "Status",
            dataIndex: "status",
            key: "status",
        },
        {
            title: "Gender",
            dataIndex: ["input", "gender"],
            key: "gender",
        },
        {
            title: "Style",
            dataIndex: "style",
            key: "style",
        },
        {
            title: "Completed",
            dataIndex: "completed",
            key: "completed",
        },
        {
            title: "Attempts",
            dataIndex: "attempts",
            key: "attempts",
        },
        {
            title: "Actions",
            key: "actions",
            render: (_text: string, job: IJob) => {
                if (job.status === JobStatus.Completed) {
                    return;
                }
                return (
                    <Button
                        type="primary"
                        onClick={() => {
                            restartJob.mutate(job._id);
                        }}
                    >
                        Restart
                    </Button>
                );
            },
        },
    ];
    if (jobsData.isLoading || restartJob.isLoading) {
        return <>Loading...</>;
    }
    const data = jobsData.data?.jobs ?? [];
    return <Table dataSource={data} columns={columns} rowKey="_id" />;
};
